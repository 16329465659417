import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-pagination" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      background: "",
      layout: _ctx.pageLayout,
      total: _ctx.total,
      "page-size": _ctx.pageSize,
      "page-sizes": _ctx.pageSizes,
      "current-page": _ctx.currentPage,
      onCurrentChange: _ctx.currentChange,
      onNextClick: _ctx.nextClick,
      onPrevClick: _ctx.prevClick
    }, null, 8, ["layout", "total", "page-size", "page-sizes", "current-page", "onCurrentChange", "onNextClick", "onPrevClick"])
  ]))
}

import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'MPagination',
  props: {
    total: {
      type: Number as PropType<number>,
      required: true,
    },
    pageSize: {
      type: Number as PropType<number>,
      required: true,
    },

    pageSizes: {
      type: Array as PropType<number[]>,
    },
    currentPage: {
      type: Number as PropType<number>,
    },
  },
  emits: ['current-change', 'next-click', 'prev-click'],
  setup(props, { emit }) {
    const pageLayout = ref(`total, ${props.pageSizes ? 'sizes,' : ''} prev, pager, next, jumper`);

    const currentChange = cur => {
      emit('current-change', cur);
    };

    const nextClick = cur => {
      emit('next-click', cur);
    };

    const prevClick = cur => {
      emit('prev-click', cur);
    };
    return {
      pageLayout,
      currentChange,
      nextClick,
      prevClick,
    };
  },
});


import { defineComponent, onMounted, ref } from 'vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import { bind, changePXId, getUserList, unBind } from '../api/user';
import MPagination from '@/components/mPagination/MPagination.vue';

import { UserList } from '../types/user';
import { ElMessage, ElMessageBox, ElButton, ElEmpty, ElInput } from 'element-plus';

export default defineComponent({
  name: 'User',
  components: { PageHeader, MPagination, ElButton, ElEmpty, ElInput },
  setup() {
    const pagination = ref({
      total: 0,
      pageSize: 10,
    });
    const loading = ref(false);
    const list = ref<UserList[]>([]);
    const initList = async (page?: number) => {
      const res = await getUserList(
        {
          page: page || 1,
          page_size: pagination.value.pageSize,
        },
        loading,
      );

      if (!res.status) {
        return;
      }

      const { data } = res;

      list.value = data?.list || [];
      pagination.value.total = data?.pages?.total || 0;
      pagination.value.pageSize = data?.pages?.page_size || 10;
    };

    // 换页
    const changePage = cur => {
      initList(cur);
    };
    // 绑定账号
    const bindLoading = ref(false);
    const triggerBind = async () => {
      const res = await bind(bindLoading);

      if (!res.status) return;

      if (!res.data) {
        ElMessage.error('接口错误，data.url不存在');
        return;
      }
      open(res.data.url);
    };

    // 解绑
    const triggerUnBind = async (item: UserList, index: number) => {
      ElMessageBox.confirm('请前往criteo手动解除此账号绑定，否则将无法再次绑定此账号。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        item.unBindLoading = true;
        const { status } = await unBind(
          { criteo_advertiser_id: item.criteo_advertiser_id },
          ref(false),
        );

        item.unBindLoading = false;

        if (!status) return;

        // 删除被解绑项
        list.value.splice(index, 1);

        ElMessage({
          type: 'success',
          message: '已成功解除绑定',
        });
      });
    };

    // 编辑像素ID
    const editorItem = ref<UserList>({} as UserList);
    const editors = ref({});
    const showEditor = (user: UserList) => {
      editorItem.value = user;
      user.isEditor = true;
      editors.value[user.id].focus();
    };
    const editPxId = async () => {
      editorItem.value.editLoading = true;
      const { status } = await changePXId(
        {
          criteo_advertiser_id: editorItem.value.criteo_advertiser_id,
          criteo_partner_id: editorItem.value.criteo_partner_id,
        },
        ref(false),
      );
      editorItem.value.editLoading = false;
      if (status) {
        editorItem.value.isEditor = false;
        ElMessage({
          type: 'success',
          message: '保存成功',
        });
      }
    };

    onMounted(() => {
      initList();
    });
    return {
      pagination,
      list,
      loading,
      triggerBind,
      bindLoading,
      changePage,
      triggerUnBind,
      showEditor,
      editPxId,
      editors,
    };
  },
});

import request from '@/tools/request';
import {
  Bind,
  BindRes,
  GetUserList,
  UserListRes,
  UnBind,
  UnBindRes,
  ChangePXId,
  ChangePXIdRes,
} from '../types/user';

// 获取商户列表
export const getUserList: GetUserList = (params, loading) => {
  return request({
    url: '/criteo-advertiser-list.html',
    params,
    loading,
  }) as Promise<UserListRes>;
};

// 绑定账号
export const bind: Bind = loading => {
  return request({
    url: '/criteo-advertiser-bind.html',
    loading,
  }) as Promise<BindRes>;
};

// 解绑账号
export const unBind: UnBind = (params, loading) => {
  return request({
    url: '/criteo-advertiser-unbind.html',
    loading,
    params,
  }) as Promise<UnBindRes>;
};

// 修改像素ID
export const changePXId: ChangePXId = (params, loading) => {
  return request({
    url: '/criteo-advertiser-save.html',
    loading,
    params,
  }) as Promise<ChangePXIdRes>;
};
